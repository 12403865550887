<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <section>
    <h3>Switch Owner Group</h3>

    <section :class="{'disabled': loading}">
      <label>Choose a group</label>
      <b-form-select v-model="selectedGroup" class="mb-2">
        <b-form-select-option value="" disabled>Select a group</b-form-select-option>
        <b-form-select-option v-for="group in groups" :key="group.uid" :value="group">{{ group.name }}</b-form-select-option>
      </b-form-select>
    </section>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer"></div>
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button variant="success" @click="switchGroupPolicy()" :class="{'disabled': loading}">Switch Group</b-button>
    </section>
  </section>
</template>
<!--eslint-enable-->

<script>
  import GroupService from '@/services/GroupService'
  import OwnersService from '@/services/OwnersService';

  export default {
    components: {},
    props: {
      owner: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        loading: false,
        selectedGroup: '',
        groups: [],
      };
    },
    mounted() {
      this.getGroups();
    },
    methods: {
      getGroups() {
        this.loading = true;

        GroupService.getGroups(this.filters).then(res => {
          this.groups = res.data;
          this.removeCurrentOwnerGroupFromList();
          this.loading = false;
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get groups, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      switchGroupPolicy() {
        this.loading = true;
        OwnersService.switchGroupPolicyOnOwner(this.owner.uid, this.selectedGroup.uid).then(() => {
          this.$toast.success('Switched group on owner Successfully', {
            toastClassName: ['toast-std', 'success-toast'],
          });
          this.$emit('refresh');
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not switch group on owner, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        }).finally(() => {
          this.loading = false;
        });
      },
      removeCurrentOwnerGroupFromList() {
        this.groups = this.groups.filter(group => this.owner.group_uid !== group.uid);
      },
    },
  };
</script>
