<style lang="scss"></style>

<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(updateOwner)">
        <section class="mb-2">
          <div>
            <label>Identifier - must be unique</label>
            <validation-provider
              v-slot="validationContext"
              name="Identifier"
              :rules="{ required: true, min: 3 }"
            >
              <b-form-input
                v-model="owner.identifier"
                :state="getValidationState(validationContext)" class="mb-1" placeholder="Enter identifier*"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </div>

          <div>
            <label>Additional information</label>
            <validation-provider
              v-slot="validationContext"
              name="Identifier"
              :rules="{ required: false }"
            >
              <b-form-textarea
                v-model="owner.additional_info"
                :state="getValidationState(validationContext)" class="mb-1" placeholder="Enter identifier*"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </div>
        </section>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer" />
          <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
          <b-button variant="primary" type="submit">Update owner</b-button>
        </section>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
  import OwnersService from '../../../services/OwnersService';

  export default {
    components: {},
    props: {
      ownerExisting: {
        required: true,
        type: Object,
      },
    },
    data() {
      return {
        owner: {
          identifier: '',
          additional_info: '',
        },
      };
    },
    mounted() {
      this.owner = { ...this.ownerExisting };
      delete this.owner.uid;
    },
    methods: {
      updateOwner() {
        this.owner.identifier = this.owner.identifier.trim();
        OwnersService.updateOwner(this.ownerExisting.uid, this.owner).then(() => {
          this.$toast.success(`Updated owner ${this.owner.identifier} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.$emit('refresh', true);
        }).catch(err => {
          const res = err.response;
          let errorText = 'Could not update owner, please refresh and try again';

          if (res && res.data.error) {
            errorText = res.data.error;
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
    },
  };
</script>
