<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="users-page">

    <b-card class="" title="Owner of Devices">
      <b-card-text>This page shows all device owners in your enterprise.</b-card-text>
    </b-card>

    <b-row>
      <b-col xl="12">
        <b-card no-body title="Device information and Security 🔒">
          <b-card-header>
            <b-card-title>Device information and Security 🔒</b-card-title>
            <b-card-text class="font-small-2 mr-25 mb-0">
              Updated: 1 min ago
            </b-card-text>
          </b-card-header>
          <b-card-body>
            <b-card-text v-if="!loading">
                <span v-if="deviceStats.unsecure > 0">
                  There are currently
                  <router-link :to="{ path: 'devices?secure=false', params: { secure: false } }" class="font-weight-bold text-nowrap">
                    {{ deviceStats.unsecure }} devices
                  </router-link>
                  registered which are unsecure.
                </span>
              <span v-if="deviceStats.unsecure === 0">
                  There are currently no devices which have any security issues 🎉
                </span>
            </b-card-text>
          </b-card-body>
          <b-card-body class="statistics-body">
            <b-row>

              <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
                <router-link :to="{ path: 'devices' }" class="font-weight-bold text-nowrap">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-primary">
                        <feather-icon size="24" icon="TabletIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ deviceStats.total }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        Devices
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </router-link>
              </b-col>

              <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-success">
                      <feather-icon size="24" icon="LockIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ devicesSecure }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ 'Devices Secure: ' + deviceStats.secure }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>

              <b-col xl="3" sm="6" class="mb-2 mb-xl-0">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-info">
                      <feather-icon size="24" icon="SearchIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h4 class="font-weight-bolder mb-0">
                      {{ devicesEncrypted }}
                    </h4>
                    <b-card-text class="font-small-3 mb-0">
                      {{ 'Devices Encrypted: ' + deviceStats.encrypted }}
                    </b-card-text>
                  </b-media-body>
                </b-media>
              </b-col>

              <b-col xl="3" sm="6" class="">
                <router-link :to="{ path: 'devices?secure=false' }" class="font-weight-bold text-nowrap">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-danger">
                        <feather-icon size="24" icon="AlertOctagonIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ deviceStats.unsecure }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        Issues Found
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </router-link>
              </b-col>
            </b-row>
          </b-card-body>

        </b-card>
      </b-col>
    </b-row>

    <b-card no-body class="mb-0">

      <div class="m-2">
        <b-row>
          <b-col v-if="$can('Create', 'Owner')" cols="12" md="3" lg="2" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <b-button @click="$bvModal.show('modal-add-owner')" variant="primary">Create Owner</b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="9" lg="10">
            <div class="d-flex align-items-center justify-content-end">

              <div class="col-6 pl-0" v-if="$can('Read', 'Group')">
                <model-list-select :list="groups"
                                   @input="startNewSearch()"
                                   :class="{'disabled': groups.length === 0}"
                                   v-model="filters.group_uid"
                                   option-value="uid"
                                   option-text="name"
                                   placeholder="Select a group"/>
              </div>

              <b-input-group>
                <b-form-input
                  @keydown.enter="searchOwners()"
                  v-model="searchVal"
                  class="d-inline-block"
                  placeholder="Search for an owner..."
                />
                <b-input-group-append>
                  <b-button @click="searchOwners()">Search</b-button>
                </b-input-group-append>
              </b-input-group>

              <section class="col-2 p-0 ml-1 d-flex justify-content-end align-items-center">
                <b-button @click="clearFilters()">Clear filters</b-button>
              </section>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        class="data-table"
        hover
        :items="owners"
        :fields="headers"
        :current-page="currentPage"
        :per-page="0"
        @sort-changed="onSortChanged"
      >
        <template #cell(identifier)="data">
          <router-link :to="{ name: 'owner-view', params: { uid: data.item.uid } }" class="font-weight-bold d-block text-nowrap">
            {{data.item.identifier}}
          </router-link>
        </template>
        <template #cell(last_seen)="data">
          <span v-if="data.item.last_seen">
            {{data.item.last_seen | formatDateTime}}
          </span>
          <span v-else>
            Never
          </span>
        </template>
        <template #cell(group_name)="data">
            {{data.item.group_name}} (v{{data.item.group_policy_version}})
        </template>
        <template #cell(actions)="data">
          <b-dropdown class="m-0"  variant="link" right toggle-class="text-decoration-none" no-caret>
            <template #button-content>
              <i class="fas fa-ellipsis-v"></i>
            </template>
            <b-dropdown-item-button class="full-nested-btn" v-if="$can('Update', 'Owner')"
                                    @click="showOwnerModal(data.item, 'modal-update-owner')">
              Update owner
            </b-dropdown-item-button>
            <b-dropdown-item-button class="full-nested-btn" v-if="$can('Delete', 'Owner')"
                                    @click="showOwnerModal(data.item, 'modal-delete-owner')">
              Delete owner
            </b-dropdown-item-button>
            <b-dropdown-item-button class="full-nested-btn" v-if="$can('Update', 'Owner')"
                                    @click="showOwnerModal(data.item, 'modal-password-owner')">
              Change pin
            </b-dropdown-item-button>
            <b-dropdown-item-button class="full-nested-btn" v-if="$can('Update', 'Owner')"
                                    @click="showOwnerModal(data.item, 'modal-policy-owner')">
              Switch Group Policy
            </b-dropdown-item-button>
          </b-dropdown>
        </template>
      </b-table>
      <b-row>
        <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
          <b-pagination @change="getMoreOwners" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
        </b-col>
        <b-col class="p-2 pl-3" v-if="total_count === 0">No Owners found</b-col>
      </b-row>

    </b-card>

    <b-modal id="modal-add-owner" title="Add new owner" hide-footer>
      <add-owner @refresh="refreshOwners()" @close="closeModals()"></add-owner>
    </b-modal>

    <b-modal id="modal-update-owner" title="Update owner" hide-footer>
      <update-owner v-if="selectedOwner" :owner-existing="selectedOwner" @refresh="refreshOwners()" @close="closeModals()"></update-owner>
    </b-modal>

    <b-modal id="modal-password-owner" title="Change owner pin" hide-footer>
      <change-password-owner v-if="selectedOwner" :owner="selectedOwner" @refresh="refreshOwners()" @close="closeModals()"></change-password-owner>
    </b-modal>

    <b-modal id="modal-policy-owner" title="Update Group Policy" hide-footer>
      <switch-owner-policy-group @close="closeModals()" @refresh="refreshOwners()" :owner="selectedOwner"></switch-owner-policy-group>
    </b-modal>

    <b-modal id="modal-delete-owner" title="Delete owner" hide-footer>
      <delete-modal
        v-if="selectedOwner"
        @close="closeModals()" @delete="deleteOwner(selectedOwner)"
        :subtitle="selectedOwner.identifier"
        title="Are you sure you wish to delete owner"></delete-modal>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
  import ChangePasswordOwner from '@/views/owners/sections/ChangePasswordOwner.vue';
  import SwitchOwnerPolicyGroup from '@/views/owners/sections/SwitchOwnerPolicyGroup.vue';
  import OwnersService from '../../services/OwnersService';
  import AddOwner from './sections/AddOwner.vue';
  import DeleteModal from '../../components/modals/DeleteModal.vue';
  import UpdateOwner from './sections/UpdateOwner.vue';
  import GroupService from '@/services/GroupService';
  import helperService from '@/services/HelperService';
  import 'vue-search-select/dist/VueSearchSelect.css';
  import { ModelListSelect } from 'vue-search-select'
  import {BCard, BCardText} from "bootstrap-vue";
  import DeviceService from "@/services/DeviceService";

  export default {
    name: 'Owners',
    components: {
      BCard, BCardText,
      ChangePasswordOwner,
      AddOwner,
      DeleteModal,
      UpdateOwner,
      SwitchOwnerPolicyGroup,
      ModelListSelect,
    },
    props: {},
    data() {
      return {
        selectedOwner: null,
        deviceStats: {
          total: '-',
          encrypted: 0,
          unencrypted: 0,
          secure: 0,
          unsecure: 0,
        },
        loading: true,
        headers: [
          {
            key: 'identifier',
            label: 'Identifier',
            sortable: true,
          },
          {
            key: 'additional_info',
            label: 'Additional Info',
            sortable: true,
          },
          {
            key: 'last_seen',
            label: 'Last Seen',
            sortable: true,
          },
          {
            key: 'group_name',
            label: 'Group Name',
          },
          {
            key: 'actions',
            label: '',
            class: 'text-right',
          },
        ],
        owners: [],
        groups: [],
        currentPage: 1,
        searchVal: '',
        filters: {
          page: 0,
          page_size: 25,
          search: '',
          group_uid: '',
          order_by: 'asc',
          order: 'identifier',
        },
        total_count: 0,
      };
    },
    computed: {
      // a computed getter
      devicesSecure() {
        // `this` points to the vm instance
        try {
          if (this.deviceStats.total === '-') {
            return '-'
          }
          if (this.deviceStats.total === 0) {
            return '100%';
          }
          return `${((this.deviceStats.secure / this.deviceStats.total) * 100).toFixed(0)}%`;
        } catch (err) {
          return '...';
        }
      },
      devicesEncrypted() {
        // `this` points to the vm instance
        try {
          if (this.deviceStats.total === '-') {
            return '-'
          }
          if (this.deviceStats.total === 0) {
            return '100%';
          }
          return `${((this.deviceStats.encrypted / this.deviceStats.total) * 100).toFixed(0)}%`;
        } catch (err) {
          return '...';
        }
      },
    },
    watch: {
      filters: {
        deep: true,
        handler: function(newVal) {
          let queryString = `?page=${newVal.page+1}`;
          if(newVal.search !== '') {
            queryString += `&search=${newVal.search}`
          }
          if(newVal.group_uid !== '') {
            queryString += `&group_uid=${newVal.group_uid}`
          }
          if(newVal.order_by !== '') {
            queryString += `&order_by=${newVal.order_by}`
          }
          if(newVal.order !== '') {
            queryString += `&order=${newVal.order}`
          }
          history.replaceState({}, null, `${this.$route.path}${queryString}`)
        }
      }
    },
    mounted() {
      this.loading = true
      // Set filters from query params
      if(this.$route.query.page) {
        this.currentPage = parseInt(this.$route.query.page, 10)
        this.filters.page = this.currentPage - 1
      }
      if(this.$route.query.search) {
        this.filters.search = this.$route.query.search
        this.searchVal = this.$route.query.search
      }
      if(this.$route.query.group_uid) {
        this.filters.group_uid = this.$route.query.group_uid
      }
      if(this.$route.query.order_by) {
        this.filters.order_by = this.$route.query.order_by
      }
      if(this.$route.query.order) {
        this.filters.order = this.$route.query.order
      }
      this.getOwners();
      if(this.$can('Read', 'Group')) {
        this.getGroups();
      }

      this.getDeviceStats()
      this.loading = false
    },
    methods: {
      onSortChanged({sortBy, sortDesc}) {
        this.filters.order_by = sortDesc ? 'desc' : 'asc'
        this.filters.order = sortBy
        this.searchOwners()
      },
      clearFilters() {
        this.currentPage = 1
        this.searchVal = ''
        this.filters = {
          page: 0,
          page_size: 25,
          search: '',
          group_uid: '',
          order_by: 'asc',
          order: 'identifier',
        }
        this.searchOwners()
      },
      async getGroups() {
        try {
          const {data} = await GroupService.getGroups()
          this.groups = data
        } catch(err) {
          helperService.showNotfyErr(this.$toast, err, 'Cpuld not fetch groups. Please refresh and try again')
        }
      },
      startNewSearch() {
        if (this.timeout) {
          clearTimeout(this.timeout);
        }

        this.timeout = setTimeout(() => {
          this.searchOwners();
        }, 500);
      },
      refreshOwners() {
        this.closeModals();
        this.searchOwners();
      },
      closeModals() {
        this.$bvModal.hide('modal-add-owner');
        this.$bvModal.hide('modal-delete-owner');
        this.$bvModal.hide('modal-update-owner');
        this.$bvModal.hide('modal-password-owner');
        this.$bvModal.hide('modal-policy-owner');
        this.$bvModal.hide('modal-qr');
      },
      searchOwners() {
        this.filters.search = this.searchVal
        this.currentPage = 1;
        this.filters.page = 0;
        this.getOwners();
      },
      getMoreOwners(val) {
        this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
        this.getOwners();
      },
      getOwners() {
        OwnersService.getOwners(this.filters).then(res => {
          this.owners = res.data.data;
          this.filters.page = res.data.meta.page;
          this.filters.page_size = res.data.meta.page_size;
          this.total_count = res.data.meta.total_count;
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get owners, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      setSelectedOwner(user) {
        this.selectedOwner = user;
      },
      showOwnerModal(user, modalName) {
        this.setSelectedOwner(user);
        this.$bvModal.show(modalName);
      },
      deleteOwner(owner) {
        OwnersService.deleteOwner(owner.uid).then(() => {
          this.$toast.success(`Deleted owner ${owner.identifier} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.refreshOwners();
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not delete owner, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      async getDeviceStats() {
        DeviceService.stats().then(res => {
          this.deviceStats = res.data;
        }).catch(err => {
          helperService.showNotfyErr(err, 'Could not get devices, please refresh and try again')
        })
      },
    },
  };
</script>
