<style lang="scss">
.update-owner-modal{
  &__roles{
    max-height: 200px;
    overflow-y: auto;
  }
}
</style>

<template>
  <div class="update-owner-modal">
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(updateOwnerPassword)">
        <section class="mb-2">

          <small class="text-primary mb-2" style="display: block;">
            {{ info_text }}
          </small>
          <validation-provider
            v-slot="validationContext"
            name="Password"
            :rules="rules"
          >
            <b-input-group class="mb-2">
              <b-form-input v-model="password.new_password"
                            :rules="rules"
                            :state="getValidationState(validationContext)" required :type="showPassword ? 'text' : 'password'" placeholder="Enter new pin*"
              />
              <b-input-group-append>
                <b-button @click="showPassword = !showPassword">
                  <i class="fas" :class="showPassword ? 'fa-eye' : 'fa-eye-slash'" />
                </b-button>
              </b-input-group-append>
              <small class="text-danger full-basis">{{ validationContext.errors[0] }}</small>
            </b-input-group>
          </validation-provider>

          <validation-provider
            v-slot="validationContext"
            name="Password-Confirm"
            :rules="{ required: true, is: password.new_password }"
          >
            <b-input-group>
              <b-form-input v-model="password.new_password_confirm"
                            :state="getValidationState(validationContext)" required :type="showPassword2 ? 'text' : 'password'" placeholder="Re-enter new pin*"
              />
              <b-input-group-append>
                <b-button @click="showPassword2 = !showPassword2">
                  <i class="fas" :class="showPassword2 ? 'fa-eye' : 'fa-eye-slash'" />
                </b-button>

              </b-input-group-append>
              <small class="text-danger full-basis">{{ validationContext.errors[0] }}</small>
            </b-input-group>

          </validation-provider>
        </section>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer" />
          <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
          <b-button variant="primary" type="submit">Change Pin</b-button>
        </section>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import OwnersService from '@/services/OwnersService';
import ProfileService from "@/services/ProfileService";



export default {
  components: {},
  props: {
    owner: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      showPassword: false,
      showPassword2: false,
      password: {
        new_password: '',
        new_password_confirm: '',
      },
      rules: {required: true, min: 6, allowedRangeAsciiPrintable: true},
      info_text: '',
    };
  },
  mounted() {
    this.getProfile();
  },
  methods: {
    updateOwnerPassword() {
      OwnersService.changePassword(this.owner.uid, this.password.new_password_confirm).then(() => {
        this.$toast.success('Updated owner password Successfully', {
          toastClassName: ['toast-std', 'success-toast'],
        });

        this.$emit('refresh', true);
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not update owner password, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
    getProfile() {
      ProfileService.getProfile().then(res => {
        this.setRules(res.data.password_quality, res.data.owner_password_length, res.data.owner_strong_password_required)
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not get profile, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      })
    },
    setRules(passwordQuality, passwordLength, strongPasswordRequired) {
      const strongPassText = strongPasswordRequired ? " Character patterns and repeated characters are not allowed." : "";
      switch (passwordQuality) {
        case "ALPHANUMERIC":
          this.rules = {required: true, min: passwordLength, lettersOrSpecial: true, someNumeric: true, allowedRangeAsciiPrintable: true, strongPasswordSequence: strongPasswordRequired, strongPasswordPattern: strongPasswordRequired}
          this.info_text = "Alphanumeric, Required length `"+passwordLength+"`."+strongPassText+" Complexity rules can be configured on your Enterprise page."
          break;
        case "NUMERIC":
          this.rules = {required: true, min: passwordLength, numeric: true, strongPasswordSequence: strongPasswordRequired, strongPasswordPattern: strongPasswordRequired}
          this.info_text = "Numeric, Required length `"+passwordLength+"`."+strongPassText+" Complexity rules can be configured on your Enterprise page."
          break;
        case "SOMETHING" || "NONE":
          this.rules = {required: true, min: passwordLength, allowedRangeAsciiPrintable: true, strongPasswordSequence: strongPasswordRequired, strongPasswordPattern: strongPasswordRequired}
          this.info_text = "Required length `"+passwordLength+"`."+strongPassText+" Complexity rules can be configured on your Enterprise page."
          break;
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
