<style lang="scss"></style>

<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(addOwner)">
        <section class="mb-2">
          <div>
            <label>Identifier - must be unique</label>
            <validation-provider
              v-slot="validationContext"
              name="Identifier"
              :rules="{ required: true, min: 3 }"
            >
              <b-form-input
                v-model="owner.identifier"
                :state="getValidationState(validationContext)" class="mb-1" placeholder="Enter identifier"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </div>

          <div>
            <label>Additional information</label>
            <validation-provider
              v-slot="validationContext"
              name="Additional Info"
              :rules="{ required: false }"
            >
              <b-form-textarea
                v-model="owner.additional_info"
                :state="getValidationState(validationContext)" class="mb-1" placeholder="Enter additional info"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
          </div>

          <div>
            <label>Password/Pin</label>
            <validation-provider
              v-slot="validationContext"
              name="Password"
              :rules="rules"
            >
              <br>
              <small class="text-primary">
                {{ info_text }}
              </small>
              <b-input-group>
                <b-form-input v-model="owner.password" required :type="showPassword ? 'text' : 'password'"
                              placeholder="Enter password*"
                              :state="getValidationState(validationContext)"
                />
                <b-input-group-append>
                  <b-button @click="showPassword = !showPassword">
                    <i class="fas" :class="showPassword ? 'fa-eye' : 'fa-eye-slash'" />
                  </b-button>

                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
            </validation-provider>
          </div>

          <div>
            <label>Group</label>
            <validation-provider
              v-slot="validationContext"
              name="Group"
              :rules="{ required: true }"
            >
              <b-form-select v-model="owner.group_uid" class="mb-2" :state="getValidationState(validationContext)">
                <b-form-select-option value="" disabled>Choose a group</b-form-select-option>
                <b-form-select-option v-for="option in groups" :key="option.uid" :value="option.uid">{{ option.name }}</b-form-select-option>
              </b-form-select>
            </validation-provider>
          </div>
        </section>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer" />
          <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
          <b-button variant="primary" type="submit">Add new owner</b-button>
        </section>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import GroupService from '@/services/GroupService';
import OwnersService from '../../../services/OwnersService';
import ProfileService from "@/services/ProfileService";

export default {
  components: {},
  props: {},
  data() {
    return {
      groups: [],
      showPassword: false,
      owner: {
        identifier: '',
        additional_info: '',
        password: '',
        group_uid: '',
      },
      rules: {required: true, min: 6, allowedRangeAsciiPrintable: true},
      info_text: '',
    };
  },
  mounted() {
    this.getGroups();
    this.getProfile();
  },
  methods: {
    getGroups() {
      GroupService.getGroups().then(res => {
        this.groups = res.data;
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not get groups, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      })
    },
    getProfile() {
      ProfileService.getProfile().then(res => {
        this.setRules(res.data.password_quality, res.data.owner_password_length, res.data.owner_strong_password_required)
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not get profile, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      })
    },
    setRules(passwordQuality, passwordLength, strongPasswordRequired) {
      const strongPassText = strongPasswordRequired ? " Character patterns and repeated characters are not allowed." : "";
      switch (passwordQuality) {
        case "ALPHANUMERIC":
          this.rules = {required: true, min: passwordLength, lettersOrSpecial: true, someNumeric: true, allowedRangeAsciiPrintable: true, strongPasswordSequence: strongPasswordRequired, strongPasswordPattern: strongPasswordRequired}
          this.info_text = "Alphanumeric, Required length `"+passwordLength+"`."+strongPassText+" Complexity rules can be configured on your Enterprise page."
          break;
        case "NUMERIC":
          this.rules = {required: true, min: passwordLength, numeric: true, strongPasswordSequence: strongPasswordRequired, strongPasswordPattern: strongPasswordRequired}
          this.info_text = "Numeric, Required length `"+passwordLength+"`."+strongPassText+" Complexity rules can be configured on your Enterprise page."
          break;
        case "SOMETHING" || "NONE":
          this.rules = {required: true, min: passwordLength, allowedRangeAsciiPrintable: true, strongPasswordSequence: strongPasswordRequired, strongPasswordPattern: strongPasswordRequired}
          this.info_text = "Required length `"+passwordLength+"`."+strongPassText+" Complexity rules can be configured on your Enterprise page."
          break;
      }
    },
    addOwner() {
      this.owner.identifier = this.owner.identifier.trim();
      OwnersService.createOwner(this.owner).then(() => {
        this.$toast.success(`Added owner ${this.owner.identifier} Successfully`, {
          toastClassName: ['toast-std', 'success-toast'],
        });

        this.$emit('refresh', true);
      }).catch(err => {
        const res = err.response;
        let errorText = 'Could not add owner, please refresh and try again';

        if (res && res.data.error) {
          errorText = res.data.error;
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
  },
};
</script>
